import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { Anchor } from '@twilio-paste/anchor';
import { Box } from '@twilio-paste/box';
import { Text } from '@twilio-paste/text';
import { Stack } from '@twilio-paste/stack';
import { MediaObject, MediaFigure, MediaBody } from '@twilio-paste/media-object';
import { ErrorIcon } from '@twilio-paste/icons/esm/ErrorIcon';
import { Disclosure, DisclosureHeading, DisclosureContent, useDisclosureState } from '@twilio-paste/disclosure';
import Changelog from '@twilio-paste/disclosure/CHANGELOG.md';
import { Callout, CalloutTitle, CalloutText } from '../../../components/callout';
import { SidebarCategoryRoutes } from '../../../constants';
import { StateHookExample } from '../../../component-examples/DisclosureExamples';
export const pageQuery = graphql`
  {
    allPasteComponent(filter: {name: {eq: "@twilio-paste/disclosure"}}) {
      edges {
        node {
          name
          description
          status
          version
        }
      }
    }
    mdx(fields: {slug: {eq: "/components/disclosure/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentHeader = makeShortcode("ComponentHeader");
const PageAside = makeShortcode("PageAside");
const LivePreview = makeShortcode("LivePreview");
const ChangelogRevealer = makeShortcode("ChangelogRevealer");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <ComponentHeader name={props.pageContext.frontmatter.title} categoryRoute={SidebarCategoryRoutes.COMPONENTS} githubUrl="https://github.com/twilio-labs/paste/tree/main/packages/paste-core/components/disclosure" storybookUrl="/?path=/story/components-disclosure--all-variants" data={props.data.allPasteComponent.edges} mdxType="ComponentHeader" />
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <h2>{`Guidelines`}</h2>
        <h3>{`About Disclosure`}</h3>
        <p>
  {props.pageContext.frontmatter.description} It uses the <Anchor href="/components/heading" mdxType="Anchor">Paste Heading</Anchor>{' '}
  component to create consistency across pages and content hierarchy.
        </p>
        <h3>{`Accessibility`}</h3>
        <p>{`You `}<strong parentName="p">{`must not`}</strong>{` nest focusable or actionable elements inside the `}<inlineCode parentName="p">{`DisclosureHeading`}</inlineCode>{`. Nested actionable elements are difficult to discover for users of assistive technology as their existence is often not announced.`}</p>
        <h2>{`Examples`}</h2>
        <h3>{`Default Disclosure`}</h3>
        <p>{`The default variant of a Disclosure is a composition of the `}<a parentName="p" {...{
            "href": "/components/heading"
          }}>{`Paste Heading`}</a>{` and a chevron button icon as the "trigger" for the disclosure. This trigger controls the appearance of content that is below it.`}</p>
        <p>{`The `}<inlineCode parentName="p">{`DisclosureHeading`}</inlineCode>{` takes the same props as the `}<a parentName="p" {...{
            "href": "/components/heading"
          }}>{`Heading`}</a>{` component so you can control the semantic heading level and it's visual appearance through variants.`}</p>
        <LivePreview scope={{
          Disclosure,
          DisclosureHeading,
          DisclosureContent,
          Stack
        }} mdxType="LivePreview">
  {`<Stack orientation="vertical" spacing="space70">
  <Disclosure>
    <DisclosureHeading as="h2" variant="heading30">
      Between the World and Me by Ta-Nehisi Coates
    </DisclosureHeading>
    <DisclosureContent>
      But race is the child of racism, not the father. And the process of naming “the people” has never been a matter of genealogy and physiognomy so much as one of hierarchy. Difference in hue and hair is old. But the belief in the preeminence of hue and hair, the notion that these factors can correctly organize a society and that they signify deeper attributes, which are indelible—this is the new idea at the heart of these new people who have been brought up hopelessly, tragically, deceitfully, to believe that they are white.
    </DisclosureContent>
  </Disclosure>
  <Disclosure>
    <DisclosureHeading as="h2" variant="heading10">
      Disclosure Heading
    </DisclosureHeading>
    <DisclosureContent>
      Content
    </DisclosureContent>
  </Disclosure>
  <Disclosure>
    <DisclosureHeading as="h2" variant="heading20">
      Disclosure Heading
    </DisclosureHeading>
    <DisclosureContent>
      Content
    </DisclosureContent>
  </Disclosure>
  <Disclosure>
    <DisclosureHeading as="h2" variant="heading30">
      Disclosure Heading
    </DisclosureHeading>
    <DisclosureContent>
      Content
    </DisclosureContent>
  </Disclosure>
  <Disclosure>
    <DisclosureHeading as="h2" variant="heading40">
      Disclosure Heading
    </DisclosureHeading>
    <DisclosureContent>
      Content
    </DisclosureContent>
  </Disclosure>
  <Disclosure>
    <DisclosureHeading as="h2" variant="heading50">
      Disclosure Heading
    </DisclosureHeading>
    <DisclosureContent>
      Content
    </DisclosureContent>
  </Disclosure>
  <Disclosure>
    <DisclosureHeading as="h2" variant="heading60">
      Disclosure Heading
    </DisclosureHeading>
    <DisclosureContent>
      Content
    </DisclosureContent>
  </Disclosure>
</Stack>`}
        </LivePreview>
        <h3>{`Contained Disclosure`}</h3>
        <p>{`The `}<inlineCode parentName="p">{`contained`}</inlineCode>{` variant of the Disclosure takes the default composition and wraps it in the `}<a parentName="p" {...{
            "href": "/components/card"
          }}>{`Paste Card`}</a>{` component. In this instance we do not expose the props used by Card, but all previously mentioned Disclosure props behave in the same way as the default variant.`}</p>
        <LivePreview scope={{
          Disclosure,
          DisclosureHeading,
          DisclosureContent,
          Stack
        }} mdxType="LivePreview">
  {`<Stack orientation="vertical" spacing="space70">
  <Disclosure variant="contained">
    <DisclosureHeading as="h2" variant="heading10">
      Disclosure Heading
    </DisclosureHeading>
    <DisclosureContent>
      Content
    </DisclosureContent>
  </Disclosure>
  <Disclosure variant="contained">
    <DisclosureHeading as="h2" variant="heading20">
      Disclosure Heading
    </DisclosureHeading>
    <DisclosureContent>
      Content
    </DisclosureContent>
  </Disclosure>
  <Disclosure variant="contained">
    <DisclosureHeading as="h2" variant="heading30">
      Disclosure Heading
    </DisclosureHeading>
    <DisclosureContent>
      Content
    </DisclosureContent>
  </Disclosure>
  <Disclosure variant="contained">
    <DisclosureHeading as="h2" variant="heading40">
      Disclosure Heading
    </DisclosureHeading>
    <DisclosureContent>
      Content
    </DisclosureContent>
  </Disclosure>
  <Disclosure variant="contained">
    <DisclosureHeading as="h2" variant="heading50">
      Disclosure Heading
    </DisclosureHeading>
    <DisclosureContent>
      Content
    </DisclosureContent>
  </Disclosure>
  <Disclosure variant="contained">
    <DisclosureHeading as="h2" variant="heading60">
      Disclosure Heading
    </DisclosureHeading>
    <DisclosureContent>
      Content
    </DisclosureContent>
  </Disclosure>
</Stack>`}
        </LivePreview>
        <h3>{`Controlling initial state`}</h3>
        <p>{`You can control the initial state of the disclosure to be expanded or collapsed by setting the `}<inlineCode parentName="p">{`visible`}</inlineCode>{` prop.`}</p>
        <LivePreview scope={{
          Disclosure,
          DisclosureHeading,
          DisclosureContent
        }} mdxType="LivePreview">
  {`<Disclosure visible>
  <DisclosureHeading as="h2" variant="heading30">
    Disclosure Heading
  </DisclosureHeading>
  <DisclosureContent>
    Content
  </DisclosureContent>
</Disclosure>`}
        </LivePreview>
        <h3>{`Using state hooks`}</h3>
        <Callout variant="warning" mdxType="Callout">
  <CalloutTitle mdxType="CalloutTitle">🚨 ATTENTION!! 🚨</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    If you decided to use state hooks, the Paste team is <strong>NOT</strong> responsible for debugging any issues in
    your application.
  </CalloutText>
        </Callout>
        <p>{`The `}<inlineCode parentName="p">{`state`}</inlineCode>{` prop allows you to hook into, and keep in sync the state of the Disclosure and the state of your application.`}</p>
        <p>{`This will allow you to set initial state and merge the returned state from the hook with the application state,
and keep it in sync with user interactions.`}</p>
        <p>{`You could use this if you need to wait for a response from an API before opening the Disclosure content. In the
example below, we're simulating the response time by adding a delay.`}</p>
        <LivePreview scope={{
          useDisclosureState,
          Disclosure,
          DisclosureHeading,
          DisclosureContent
        }} noInline language="jsx" mdxType="LivePreview">
  {StateHookExample}
        </LivePreview>
        <h2>{`States`}</h2>
        <h3>{`Disabled`}</h3>
        <p>{`Each Disclosure can be disabled by setting the `}<inlineCode parentName="p">{`disabled`}</inlineCode>{` prop on the `}<inlineCode parentName="p">{`DisclosureHeading`}</inlineCode></p>
        <LivePreview scope={{
          Disclosure,
          DisclosureHeading,
          DisclosureContent
        }} mdxType="LivePreview">
  {`<Disclosure visible>
  <DisclosureHeading as="h2" disabled variant="heading30">
    Disclosure Heading
  </DisclosureHeading>
  <DisclosureContent>
    Content
  </DisclosureContent>
</Disclosure>`}
        </LivePreview>
        <h2>{`Composition notes`}</h2>
        <p>{`Both `}<inlineCode parentName="p">{`DisclosureHeading`}</inlineCode>{` and `}<inlineCode parentName="p">{`DisclosureContent`}</inlineCode>{` accept any child component, leaving flexibility to create a large number of custom compositions for your product needs.`}</p>
        <p>{`The only thing you `}<strong parentName="p">{`must not`}</strong>{` do is place a focusable element inside the `}<inlineCode parentName="p">{`DisclosureHeading`}</inlineCode>{`, see `}<a parentName="p" {...{
            "href": "/components/disclosure#accessibility"
          }}>{`accessibility notes`}</a>{`.`}</p>
        <LivePreview scope={{
          Disclosure,
          DisclosureHeading,
          DisclosureContent,
          Text
        }} mdxType="LivePreview">
  {`<Disclosure>
  <DisclosureHeading as="h2" variant="heading30">
    Disclosure Heading <Text color="colorTextWeak" fontSize="fontSize50" as="span">(3 new items)</Text>
  </DisclosureHeading>
  <DisclosureContent>
    Content
  </DisclosureContent>
</Disclosure>`}
        </LivePreview>
        <LivePreview scope={{
          Disclosure,
          DisclosureHeading,
          DisclosureContent,
          ErrorIcon,
          MediaObject,
          MediaFigure,
          MediaBody,
          Text
        }} mdxType="LivePreview">
  {`<Disclosure variant="contained" visible>
  <DisclosureHeading as="h2" variant="heading30">
    <MediaObject verticalAlign="center">
      <MediaBody>
        Disclosure Heading <Text color="colorTextWeak" fontSize="fontSize50" as="span">- deployment issue</Text>
      </MediaBody>
      <MediaFigure>
        <ErrorIcon color="colorTextError" decorative={false} title="Error" />
      </MediaFigure>
    </MediaObject>
  </DisclosureHeading>
  <DisclosureContent>
    Content
  </DisclosureContent>
</Disclosure>`}
        </LivePreview>
        <h2>{`Anatomy`}</h2>
        <h3>{`Disclosure`}</h3>
        <h4>{`Contained`}</h4>
        <p>{`Contained Disclosure variants share the Paste `}<a parentName="p" {...{
            "href": "/components/card/#anatomy"
          }}>{`Card anatomy`}</a>{`.`}</p>
        <h3>{`DisclosureHeading`}</h3>
        <p>{`Same Anatomy as `}<a parentName="p" {...{
            "href": "/components/heading"
          }}>{`Heading`}</a>{`, plus:`}</p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Property`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default token`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Modifiable?`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`background-color`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`color-background-body`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`border-radius`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`border-radius-20`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`border-bottom-left-radius`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Default: border-radius-20, Contained: border-radius-0`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`border-bottom-right-radius`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Default: border-radius-20, Contained: border-radius-0`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`padding`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`space-30`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
          </tbody>
        </table>
        <h4>{`Expanded`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Property`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default token`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Modifiable?`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`background-color`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`color-background`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
          </tbody>
        </table>
        <h4>{`Hover`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Property`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default token`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Modifiable?`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`background-color`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`color-background-dark`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
          </tbody>
        </table>
        <h4>{`Focus`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Property`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default token`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Modifiable?`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`box-shadow`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`shadow-focus`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
          </tbody>
        </table>
        <h3>{`DisclosureContent`}</h3>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Property`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default token`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Modifiable?`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`padding`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`space-50`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
          </tbody>
        </table>
        <hr></hr>
        <h2>{`Usage Guide`}</h2>
        <h3>{`API`}</h3>
        <h4>{`Installation`}</h4>
        <pre><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`yarn add @twilio-paste/disclosure - or - yarn add @twilio-paste/core
`}</code></pre>
        <h4>{`Usage`}</h4>
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`import {Disclosure, DisclosureHeading, DisclosureContent} from '@twilio-paste/disclosure';

const PopoverExample: React.FC = () => {
  return (
    <Disclosure>
      <DisclosureHeading as="h2" variant="heading20">
        Disclosure Heading
      </DisclosureHeading>
      <DisclosureContent>Disclosure content</DisclosureContent>
    </Disclosure>
  );
};
`}</code></pre>
        <h4>{`Props`}</h4>
        <h4>{`Disclosure Props`}</h4>
        <p>{`All the regular HTML attributes (`}<inlineCode parentName="p">{`role`}</inlineCode>{`, `}<inlineCode parentName="p">{`aria-*`}</inlineCode>{`, `}<inlineCode parentName="p">{`type`}</inlineCode>{`, and so on) including the following custom props:`}</p>
        <h5><inlineCode parentName="h5">{`baseId`}</inlineCode>{` prop`}</h5>
        <p>{`ID that will serve as a base for all the items IDs.`}</p>
        <h5><inlineCode parentName="h5">{`visible`}</inlineCode>{` prop`}</h5>
        <p>{`Whether it's visible or not.`}</p>
        <h5><inlineCode parentName="h5">{`variant`}</inlineCode>{` prop`}</h5>
        <p>{`Changes the styling on the component based on the variant selected.`}</p>
        <h4>{`DisclosureHeading Props`}</h4>
        <p>{`All the regular HTML attributes (`}<inlineCode parentName="p">{`role`}</inlineCode>{`, `}<inlineCode parentName="p">{`aria-*`}</inlineCode>{`, `}<inlineCode parentName="p">{`type`}</inlineCode>{`, and so on).`}</p>
        <p>{`All `}<a parentName="p" {...{
            "href": "/components/heading"
          }}>{`Heading`}</a>{` props.`}</p>
        <p>{`Also including the following custom props:`}</p>
        <h5><inlineCode parentName="h5">{`disabled`}</inlineCode>{` prop`}</h5>
        <p>{`Same as the HTML attribute.`}</p>
        <h5><inlineCode parentName="h5">{`focusable`}</inlineCode>{` prop`}</h5>
        <p>{`When an element is `}<inlineCode parentName="p">{`disabled`}</inlineCode>{`, it may still be `}<inlineCode parentName="p">{`focusable`}</inlineCode>{`. It works similarly to `}<inlineCode parentName="p">{`readOnly`}</inlineCode>{` on form elements. In this case, only `}<inlineCode parentName="p">{`aria-disabled`}</inlineCode>{` will be set.`}</p>
        <h4>{`DisclosureContent Props`}</h4>
        <p>{`All the regular HTML attributes (`}<inlineCode parentName="p">{`role`}</inlineCode>{`, `}<inlineCode parentName="p">{`aria-*`}</inlineCode>{`, `}<inlineCode parentName="p">{`type`}</inlineCode>{`, and so on) including the following custom props:`}</p>
        <ChangelogRevealer mdxType="ChangelogRevealer">
  <Changelog mdxType="Changelog" />
        </ChangelogRevealer>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      